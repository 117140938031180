import React from 'react';
import { AlertModal, CheckInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { Button } from 'react-bootstrap';

interface Props {
    id: string,
    value?: boolean,
    label?: string,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}
const explanationPrompt = (
    <div>
        <p>
            With respect to each phone number and email address that you provide, you consent to Currency and affiliates, agents, assignees,
            and any lending partner to whom Currency transmits your application with, to contact you at the number by calling, texting, or
            sending other electronic messages, from time to time, for any reason about your accounts, including but not limited to, for collection
            and payment purposes. You agree that automated dialing equipment or prerecorded voice messages may be used for any of these purposes.
        </p>

        <p>
            Upon any completed application submission and approval, Currency may contact you and will share your information with the lenders
            in its network to find the best loan solution requested.
        </p>

        <p>
            You have the option to opt out of having your contact information shared with the seller before your application is submitted and approved.
        </p>

        <p>
            Once your application is submitted and approved; we will share your contact information with the seller connected to the equipment you are interested in.
        </p>

        <p>
            If you would like the seller to contact you before a lender has approved your application, simply hit NEXT. After completing the information on this
            step and clicking NEXT, will allow Currency to contact you unless you have opted out.
        </p>

        <p>
            If you DO NOT want us to send the seller your contact information until after your application is submitted and approved, please check the
            “Do not share contact info with seller”. Checking this box will not have any effect on your application.
        </p>

        <h6><strong>Is my information secure?</strong></h6>
        <p>We pride ourselves in security. Our session is protected with 256-bit encryption.</p>
    </div>
)
const handleExplanationClick = () => {
    AlertModal.alert('Sharing of Contact Information', explanationPrompt, 'primary', 'lg')
}
const DoNotShareCheck = (props: Props) => {
    return (
        <CheckInput id={props.id} className={`${props.className ?? ''} mr-1`} value={props.value} onChange={props.onChange} label={`${props.label}${props.required ? '*' : ''}`}>
            <InputNote style={{ marginTop: '-10px' }}>
                <Button className='px-0 accessible-gray' size='sm' variant="link" style={{ fontSize: '9pt' }} onClick={handleExplanationClick}>How does this affect my application?</Button>
            </InputNote>
        </CheckInput>
    );
}

export default DoNotShareCheck;