import React from 'react';
import { GenericModal, GenericModalProps } from 'sandhills-react-bootstrap-ui';
import { Button, Table } from 'react-bootstrap';

interface Props extends Omit<GenericModalProps, "disableBackDropClick" | "onHide" | "renderContent" | "renderButtons"> {
    onHide: (close: string | null) => void;
}

const CookiesPolicyModal = (props: Props) => {

    const handleClose = () => {
        props.onHide(null);
    }

    const renderBody = () => {
        return (
            <div style={{ height: 500, overflowY: 'auto' }}>
                <div style={{ marginRight: 16}}>
                    <b>1.	WHO WE ARE AND HOW TO CONTACT US</b>
                    <p>
                        This website is operated by Express Tech-Financing, LLC d.b.a. Currency ("
                        <b>Currency</b>
                        ", "
                        <b>Company</b>
                        ", "
                        <b>we</b>
                        ", "
                        <b>us</b>
                        " or "
                        <b>our</b>
                        "), a Limited Liability Company registered in the State of Delaware (USA) with registered address at Corporation Service Company 251 Little Falls Drive, Wilmington, Delaware, 19808, USA.
                    </p>
                    <p>This Cookies Policy applies to your use of our websites which are owned and operated by Currency.  </p>
                    <p>
                        Please also see our&nbsp;
                        <a href="https://www.gocurrency.com/privacy-policy/" target="_blank" rel="noopener noreferrer" className="link-primary font-weight-bold">Privacy Policy</a>
                        &nbsp;or our&nbsp;
                        <a href="https://www.gocurrency.com/ccpa-privacy-policy/" target="_blank" rel="noopener noreferrer" className="link-primary font-weight-bold">Express Tech-Financing, LLC (Currency) Privacy Policy for California Residents</a>
                        &nbsp;for further details about the ways in which we collect and use your personal data. If you have any questions or concerns about this Cookie Policy, please contact us at&nbsp;
                        <a href="mailto:privacy@gocurrency.com" target="_blank" rel="noopener noreferrer" className="link-primary font-weight-bold">privacy@gocurrency.com</a>
                        .
                    </p>
                    <b>2.	WHAT ARE COOKIES?</b>
                    <p>
                        Cookies are small data files that are stored in your web browser on your computer, tablet, or smartphone (each a "
                        <b>Device</b>
                        ") when you visit our websites. They can enable websites to remember your actions and preferences, collect analytics, and conduct marketing.
                    </p>
                    <p>
                        Cookies can be either first party cookies or third-party cookies.
                        First party cookies are provided by us and used by various features on our site.
                        Examples may include customizing a user's visit, providing statistical information, and verifying a user's identity for security purposes.
                        Third-party cookies are provided by "Third-Party" vendors who provide products and features we may have integrated into our site.
                        Examples include social media sites, ad networks, and security systems.
                    </p>
                    <p>
                        Additionally, cookies can be either session cookies or persistent cookies.
                        Session cookies are added when a user starts to browse our site or interacts with a specific feature and are deleted when the browser is closed.
                        Persistent cookies are added when a user starts to browse our site or interacts with a specific feature but may remain stored on your device until a certain termination date is reached (in terms of minutes, days or years from the creation/update of the cookie).
                    </p>
                    <b>3.	DO WE USE COOKIES?</b>
                    <p>
                        Yes, we use cookies, pixels, web beacons, and other standard internet technologies (collectively referred to as "
                        <b>Cookies</b>
                        ") to enhance the customer experience of using our websites.
                        When you visit our website, we may assign your computer cookies to facilitate access to our website, personalize your online experience, and record information about your visit.
                        By using a cookie, we may also automatically collect information about your online activity on our website, such as the web pages you visit, the links or advertisements you click on, and other actions you take while on our website.
                    </p>
                    <p>
                        Additionally, Currency or a third- party may place cookies on our websites to collect information about your interactions with our website and the content displayed on it.
                        These may also be included in e-mail messages or newsletters to determine whether messages have been opened and acted upon.
                        Some of these cookies may pass information about your interactions to these third parties which may further combine this information with personal information they collect directly from you.
                        The collection and use of that personal information are subject to the third-party's privacy policy, which we have no control.
                    </p>
                    <b>4.	WHY DO WE USE COOKIES?</b>
                    <p>
                        We use cookies for the following purposes:
                        <ul style={{ listStyleType: 'circle' }}>
                            <li>To customize your visit.</li>
                            <li>To improve our websites.</li>
                            <li>To analyze and compile collected data to build a profile about customers.</li>
                            <li>
                                For statistical purposes such as:
                                <ul style={{ listStyleType: 'circle' }}>
                                    <li>Pages viewed during a visit.</li>
                                    <li>Data is used to improve existing features and user experience on desktop, mobile, etc.</li>
                                    <li>To send various mass marketing e-mail campaigns and track statistics about the mail campaign.</li>
                                </ul>
                            </li>
                            <li>
                                To collect the following information:
                                <ul style={{ listStyleType: 'circle' }}>
                                    <li>Information on what customers view.</li>
                                    <li>Information on what customers click on.</li>
                                    <li>Information on what customers access in and through the websites, our marketing emails, and text messages including pages visited within our websites.</li>
                                    <li>Data related to customer use and patterns on websites.</li>
                                    <li>Data regarding products and services customers have visited.</li>
                                    <li>Data regarding how often users visit and use the websites.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <b>5.	WHICH COOKIES DO WE USE?</b>
                    <Table bordered={true}>
                        <thead>
                            <tr>
                                <th>Cookie</th>
                                <th>Description</th>
                                <th>First or Third Party</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CURR_UNQ_ID</td>
                                <td>This cookie is used for tacking users across sessions. This cookie allows us collect information about your session for testing and debugging.</td>
                                <td>First Party</td>
                                <td>400 days</td>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>This cookie is provided by Google and set on our domain by us to distinguish users visiting the website for analytics purposes.</td>
                                <td>First Party</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_ga &lt;container-id&gt; </td>
                                <td>This cookie is provided by Google and set on our domain by us to create a persistent session for the user for analytics purposes.</td>
                                <td>First Party</td>
                                <td>2 years</td>
                            </tr>
                            <tr>
                                <td>_gid</td>
                                <td>This cookie is provided by Google and set on our domain by us for providing analytics. For example, tracking a page view on our website.</td>
                                <td>First Party</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>_uetsid</td>
                                <td>This cookie is used to collect analytics on paid campaigns which we run on the Bing platform.</td>
                                <td>Third Party - Bing</td>
                                <td>One day</td>
                            </tr>
                            <tr>
                                <td>_uetvid</td>
                                <td>This cookie is used to collect analytics on paid campaigns which we run on the Bing platform.</td>
                                <td>Third Party - Bing</td>
                                <td>13 Months</td>
                            </tr>
                            <tr>
                                <td>Reese84</td>
                                <td>This cookie is provided by Imperva and set on our domain by us to be used for security purposes.  For example, to determine if a user will receive a captcha.</td>
                                <td>First Party</td>
                                <td>1 month</td>
                            </tr>
                            <tr>
                                <td>x-d-token</td>
                                <td>This cookie is provided by Imperva and is set on our domain by us to be used for security purposes.  For example, to determine if website requests are coming from real or automated devices</td>
                                <td>First Party</td>
                                <td>A few seconds</td>
                            </tr>
                            <tr>
                                <td>WEBSERVER</td>
                                <td>This cookie is used for server and load balancing.</td>
                                <td>First Party</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>CAKEPHP</td>
                                <td>This is cookie is used to personalize your experience.</td>
                                <td>Third Party - SiteJabber.com</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>_hjSession_</td>
                                <td>This cookie is provided by Hotjar and set on our domain by us to hold the current session data. This ensures that subsequent requests within the session window will be attributed to the same Hotjar session.</td>
                                <td>First Party</td>
                                <td>30 minutes</td>
                            </tr>
                            <tr>
                                <td>_hjSessionUser_</td>
                                <td>This cookie is provided by Hotjar and is set by us on our domain so when a user first lands on a page with the Hotjar script. It is used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.</td>
                                <td>First Party</td>
                                <td>1 year</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>Some of the above tracking cookies are used to identify repeat visitors to help improve the site, investigate possible issues, etc.</p>
                    <b>6.	DO WE HOST THIRD-PARTY COOKIES?</b>
                    <p>
                        Yes, and we have itemized these cookies in the table in section five above.
                        Cookies from third-party websites may be stored on your Device with the purpose of personalizing and/or improving the content, providing analytics about your visit to our website, and/or for security and verification purposes that you may be offered while navigating.
                    </p>
                    <b>7.	MANAGING AND DISABLING COOKIES</b>
                    <p>
                        You can control the cookies placed and retained on your Device.
                        The Privacy Policy and this Cookie Policy are provided to be transparent about our practices regarding the use of cookies and similar tracking technology.
                    </p>
                    <p>
                        For instructions on blocking and deleting cookies, see the privacy and help documentation of your specific browser's website.
                        If you use more Devices and/or browsers you will need to disable cookies on each Device and on each browser separately.
                    </p>
                    <p>
                        If you limit the ability of our websites to set cookies, this may prevent you from using certain features of our website properly and your user experience - which is no longer personalized for you - may deteriorate.
                        You may also be able to opt-out from certain cookies through third party cookie management sites.
                        If you delete your cookies from the browser, you may need to remember to re-install opt-out cookies.
                    </p>
                    <b>8.	CONTACT US</b>
                    <p>
                        We may update this Cookie Policy from time to time.
                        The updated version will be indicated by the "last updated date" and the updated version will be effective as soon as it is accessible.
                        We encourage you to review this Cookie Policy frequently to be informed about your information.
                    </p>
                    <p>
                        If there are any questions regarding this Cookie Policy, you may contact us through the following methods:
                    </p>
                    <p>
                        <b>Email:</b>
                        <p>
                            <a href="mailto:privacy@gocurrency.com" target="_blank" rel="noopener noreferrer" className="link-primary font-weight-bold">privacy@gocurrency.com</a>
                        </p>
                    </p>
                    <p>
                        <b>Phone:</b>
                        <p>844-724-7375</p>
                    </p>
                    <p>
                        <b>Mail:</b>
                        <p>Express Tech-Financing, LLC dba Currency</p>
                        <p>8930 East Raintree Drive,</p>
                        <p>Scottsdale, AZ 85260</p>
                        <p>United States</p>
                    </p>
                    <p style={{ marginTop: 50 }}>Last Updated:</p>
                    <p>Version: 8-7-2024</p>
                </div>
            </div>
        )
    }

    const renderButton = () => {
        return (
            <>
                <Button variant="primary" onClick={handleClose}>Close</Button>
            </>
        )
    }

    return (
        <GenericModal {...props} disableBackdropClick={false} renderContent={renderBody} renderButtons={renderButton} onHide={handleClose} size="xl" />
    );
}

export default CookiesPolicyModal;