import { createContext } from 'react';
import Context from './models/client/Context';
import { Theme } from './models/Theme';
import { VendorSettings } from './models/VendorSettings';
import Cookies from 'universal-cookie';

export const AppContext = createContext<Context>({ //This is the default - it should never be hit so the objects don't need to be valid
    vendorID: 0,
    topLevelPartnerVendorID: 0,
    highestTopLevelPartnerVendorID: 0,
    defaultCurrencyCode: 'USD',
    theme: {} as Theme,
    style: ``,
    phoneNumber: '',
    supportEmail: '',
    logoURL: '',
    uniqueClientID: '',
    vendorSettings: {} as { [key: string]: any; },
    cookies: {} as Cookies,
}); // TODO: Put all HTML settings in embedded class