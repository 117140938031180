import React, { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SaveApplicationResult } from '../../models/SaveApplicationResult';
import Fr8StarLink from '../../shared/Fr8StarLink';

interface Props {
    loading?: boolean,
    message?: JSX.Element | string
    appInProgress?: SaveApplicationResult | null
}

const StatusStep = (props: Props) => {
    return (
        <Card style={{ minHeight: 400 }} className={`w-100 shadow`}>
            <Card.Header style={{ fontSize: 30 }} className='bg-white text-center text-condensed font-weight-bold text-uppercase text-dark-gray'>
                &nbsp;
            </Card.Header>
            <Card.Body className='bg-tint d-flex align-items-center justify-content-center'>
                {props.loading && <Spinner as='span' animation='border' />}
                {props.message && props.message}
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between bg-white'>
                {props.appInProgress?.fr8StarRedirectURL ? (
                    <div>
                        <Fr8StarLink fr8StarRedirectURL={props.appInProgress.fr8StarRedirectURL}></Fr8StarLink>
                    </div>
                ) : (
                    <div style={{ fontSize: 30 }}>&nbsp;</div>
                )}
            </Card.Footer>
        </Card>
    );
}

export default StatusStep;