import React, { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../AppContext';
import { VendorStep } from '../../models/VendorStep';
import ButtonSpinner from '../../shared/ButtonSpinner';
import { StepQuestion } from '../../models/StepQuestion';
import { VendorStepFlow } from '../../models/VendorStepFlow';
import { MdEdit } from 'react-icons/md';
import CurrencyUtility from '../../utilities/CurrencyUtility';
import { FaPrint } from 'react-icons/fa';

interface Props {
    loading: boolean,
    questionValueDictionary: { [inputDefinitionID: number]: any },
    inputValueDictionary: { [inputDefinitionID: number]: any },
    allFlowQuestions: StepQuestion[],
    flow: VendorStepFlow,
    stepIndexHistory: number[],
    onStartOver?: () => void,
    onBack?: () => void,
    onEditStep: (stepIndex: number) => void,
    onSubmit: () => void
}

const ReviewStep = (props: Props) => {
    const context = useContext(AppContext);

    const renderQuestionValue = (value: any, controlType: string) => {

        let label = '';

        switch (controlType.toLowerCase()) {
            case 'currencyinput':
            case 'loanamountdisplay':
                // Get the currency code question
                const currencyCodeQuestion = props.allFlowQuestions.find(x => x.name.toLowerCase() === 'currency code');
                const currencyCode: string = currencyCodeQuestion ? props.questionValueDictionary[currencyCodeQuestion.inputDefinitionID] : context.defaultCurrencyCode;

                label = CurrencyUtility.formatCurrency(value, undefined, currencyCode);
                break;
            case 'birthday':
                label = value?.toLocaleDateString();
                break;
            default:
                label = value?.label?.toString()?.trim() ?? value?.toString()?.trim();
                break;
        }

        return (
            <span className='text-truncate w-50'>{label}</span>
        )
    }

    const renderQuestion = (question: StepQuestion, colWidth: number) => {

        if (question.controlType.toLowerCase() == 'imagedisplay' ||
            question.controlType.toLowerCase() == 'jointguarantorexplanationdisplay' ||
            question.controlType.toLowerCase() == 'hidden'
        )
            return null;

        return (
            <React.Fragment key={question.inputDefinitionID}>
                <Col key={question.inputDefinitionID} xs='12' md={colWidth} className='d-flex'>
                    <>
                        <span className='text-condensed w-50 mr-2 accessible-gray'><b>{question.label}:</b></span>
                        {renderQuestionValue(props.inputValueDictionary[question.inputDefinitionID], question.controlType)}
                    </>
                </Col>
            </React.Fragment>
        );
    }

    const renderStepSection = (step: VendorStep, index: number) => {

        // Show nothing for steps that were skipped
        if (!props.stepIndexHistory.includes(index))
            return null;

        return (
            <div key={step.stepID} className='review-step-section'>
                <div className='bg-white p-3 d-flex align-items-center justify-content-between'>
                    <h5 className='text-uppercase text-dark-gray text-condensed m-0 font-weight-bold'>{step.stepName}</h5>
                    <Button disabled={props.loading} title={'Edit ' + step.stepName} variant='outline-primary' size='sm' onClick={() => props.onEditStep(index)}>
                        Edit <MdEdit />
                    </Button>
                </div>
                {/* <hr className='mt-1' /> */}
                {step.questionGroups && step.questionGroups.map((group, gi) => {
                    // Only show groups that have shown questions
                    if (!group.questions.some(q => q.controlType.toLowerCase() !== 'hidden' && q.show))
                        return null;

                    return (
                        <div key={`group-${gi}-${group.groupID}`} className='py-3 px-4 review-group'>
                            <Row>
                                {group.questions && group.questions.map(question => {
                                    if (!question.show)
                                        return null;

                                    let colWidth = Math.floor(12 / group.questions.filter(x => x.dataType.toLowerCase() !== 'display only').length);
                                    if (colWidth < 6)
                                        colWidth = 6;
                                    if (question.dataType.toLowerCase() === 'display only')
                                        colWidth = 12;

                                    return renderQuestion(question, colWidth);
                                }
                                )}
                            </Row>
                        </div>
                    )
                }
                )}
                {/* <hr /> */}
            </div>
        );
    }

    return (
        <Card className='w-100 shadow'>
            <Card.Header style={{ fontSize: 30 }} className='bg-white position-relative text-center text-condensed font-weight-bold text-uppercase text-dark-gray'>
                Review
                <Button title='Print' variant='clear' size='sm' style={{ right: '1rem', top: '50%', transform: 'translate(0, -50%)' }} className='position-absolute' onClick={() => window.print()}>
                    <FaPrint size={22} className='text-dark-gray' />
                </Button>
            </Card.Header>
            <Card.Body className='bg-tint p-0'>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='position-fixed' style={{ zIndex: 4, maxWidth: '300px', maxHeight: '50px', bottom: 50 }}>
                        <Button className='p-2 w-100 h-100' style={{ fontSize: 20 }} disabled={props.loading} variant='primary' onClick={() => props.onSubmit()}>Submit My Application{props.loading && <ButtonSpinner />}</Button>
                    </div>
                </div>
                {props.flow && props.flow.steps && props.flow.steps.map(renderStepSection)}
            </Card.Body>
            <Card.Footer className='d-flex flex-row-reverse justify-content-between bg-white'>
                <div className='d-inline-flex flex-row-reverse'>
                    {props.onBack && <Button disabled={props.loading} variant='secondary' className='mr-3' onClick={props.onBack}>Back</Button>}
                </div>
                <div>
                    {props.onStartOver && <Button disabled={props.loading} variant='link' onClick={props.onStartOver}>Start Over</Button>}
                </div>
            </Card.Footer>
        </Card>
    );
}

export default ReviewStep;