import React, { useContext, useEffect } from "react";
import { GenericModal } from 'sandhills-react-bootstrap-ui';
import { Button, Col, Row } from "react-bootstrap";
import { AppContext } from "../AppContext";
import BrowserSelect from "../shared/controltypeinputs/BrowserSelect";
import ChromeFaq from "../shared/ChromeFaq";
import EdgeFaq from "../shared/EdgeFaq";

const Header = () => {
    const context = useContext(AppContext);

    const [smallScreen, setSmallScreen] = React.useState<boolean>(window.innerWidth < 768);
    const [faqModalVisible, setFaqModalVisible] = React.useState<boolean>(false);
    const [faqIsChrome, setFaqIsChrome] = React.useState<boolean>(true);


    useEffect(() => {
        window.addEventListener('resize', () => {
            setSmallScreen(window.innerWidth < 768);
        });
    }, []);

    const faqContent = () => {
        return (
            <div>
                <i>Many common issues can be resolved by clearing your browser cookie associated with the portal</i><br /><br />

                <Col>
                    <Row>
                        <p>Select your browser:</p>
                        <BrowserSelect browserName='Chrome' browserIconPath='images/dbcs_faq/chrome-logo.png' className='ml-2' onClick={() => { setFaqIsChrome(true); }} active={faqIsChrome} />
                        <BrowserSelect browserName='Edge' browserIconPath='images/dbcs_faq/edge-logo.png' className='ml-2' onClick={() => { setFaqIsChrome(false); }} active={!faqIsChrome} />
                    </Row>
                </Col>
                <hr />
                <br />
                {faqIsChrome ?
                    <ChromeFaq /> : <EdgeFaq />
                }

            </div>
        );
    }

    const faqButtons = () => {
        return (
            <Button onClick={() => { setFaqModalVisible(false); }}>
                Close
            </Button>
        );
    }

    return (
        <>
            <div style={{ height: smallScreen ? '50px' : '70px', display: 'flex', justifyContent: 'space-around', backgroundColor: 'white' }} className='w-100 header shadow align-items-center'>
                <div style={{ maxWidth: '12%' }} className='image-max-width'>
                    <a target="_blank" rel='noreferrer' href="http://www.gocurrency.com/">
                        <img alt='Currency Finance' style={{ maxWidth: '100%' }} src={context.logoURL ?? 'images/currency_finance.png'} />
                    </a>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className='header-items'>
                    <span className='item accessible-gray'>Questions?</span>
                    {context.vendorSettings["ShowFaqButton"] ?
                        <Button onClick={(faqModal) => { setFaqModalVisible(true) }} variant='link' className='text-button'>FAQ</Button> :
                        <a href={`tel:${context.phoneNumber}`} style={{ fontSize: smallScreen ? 16 : 20 }} className='text-primary font-weight-bold hover'>{context.phoneNumber}</a>
                    }
                </div>

            </div>

            <GenericModal show={faqModalVisible} size='lg' onHide={() => { setFaqModalVisible(false) }} title={`Application Router - Technical Help FAQ`} renderContent={faqContent} renderButtons={faqButtons} />
        </>
    );
}

export default Header;