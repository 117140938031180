import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DropdownInput, InputLabel } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import MonthDropdown from './MonthDropdown';
import YearInput from './YearInput';

interface Props {
    value?: Date | null,
    className?: string,
    disabled?: boolean,
    onChange: (value: Date | null) => void
}

const BirthdayInput = (props: React.PropsWithChildren<Props>) => {

    const [year, setYear] = React.useState<SelectOption | null>(null);
    const [monthIndex, setMonthIndex] = React.useState<number | null>(null);
    const [month, setMonth] = React.useState<SelectOption | null>(null);
    const [day, setDay] = React.useState<SelectOption | null>(null);
    const [dayOptions, setDayOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        if (year && monthIndex && day)
            props.onChange(new Date(year.value, monthIndex, day.value));

        else
            props.onChange(null);

    }, [year, monthIndex, day]);

    useEffect(() => {
        if (month) {
            const monthIndex = getMonthNumber(month.value);

            setMonthIndex(monthIndex);

            const dayOptions = [...Array(new Date(year?.value || 0, monthIndex + 1, 0).getDate())].map((x, index) => ({ label: (index + 1).toString(), value: index + 1 } as SelectOption));
            if (day && dayOptions.findIndex(x => day.value === x.value) === -1) {
                setDay(null);
            }
            setDayOptions(dayOptions);
        }
        else {
            setMonthIndex(null);
        }
    }, [month, year]);

    const getMonthNumber = (month: string) => {
        return new Date(Date.parse(month + " 1, 1999")).getMonth();
    }

    return (
        <Row className={props.className}>
            <Col sm xs='12'>

                <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
                    <InputLabel >
                        <span hidden>Birthday</span>
                        <MonthDropdown disabled={props.disabled} placeholder='Month' value={month} onChange={(val) => setMonth(val as SelectOption)} >
                        </MonthDropdown>
                    </InputLabel>
                </div>
            </Col>
            <Col sm xs='12'>
                <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
                    <InputLabel >
                        <span hidden>Day</span>
                        <DropdownInput disabled={props.disabled || !month} searchable placeholder='Day' options={dayOptions} value={day} onChange={(val) => setDay(val as SelectOption)} >
                        </DropdownInput>
                    </InputLabel>
                </div>
            </Col>
            <Col sm xs='12'>
                <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
                    <InputLabel>
                        <span hidden>Year</span>
                        <YearInput disabled={props.disabled} value={year} placeholder='Year' onChange={(val) => setYear(val as SelectOption)} >
                        </YearInput>
                    </InputLabel>
                </div>
            </Col>
        </Row>
    );
}

export default BirthdayInput;