import React, { useState } from 'react';
import { GenericModal, GenericModalProps, InputLabel, InputNote } from 'sandhills-react-bootstrap-ui';
import SocialSecurityNumberInput from '../../shared/controltypeinputs/SocialSecurityNumberInput';
import { Button } from 'react-bootstrap';

interface Props extends Omit<GenericModalProps, "disableBackDropClick" | "onHide" | "renderContent" | "renderButtons"> {
    onHide: (ssn: string | null) => void;
}

const SocialSecurityNumberModal = (props: Props) => {

    const [ssn, setSsn] = useState<string | undefined>(undefined);
    const [hasError, setHasError] = useState<boolean>(false);

    const handleSubmit = () => {
        // Check for length 11 because the input mask adds the dashes
        if (ssn && ssn.length === 11) {
            setHasError(false);
            props.onHide(ssn);
        } else {
            setHasError(true);
        }
    }

    const handleCancel = () => {
        setSsn(undefined);
        props.onHide(null);
    }

    const renderBody = () => {
        return (
            <>
                <p className='mb-3'>
                    We were unable to retrieve a credit report with the last four digits of your Social Security number.
                    Please confirm your full Social Security number to continue.
                </p>
                <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
                    <InputLabel>
                        <span hidden > Social Security Number </span>
                        <SocialSecurityNumberInput placeholder='XXX-XX-XXXX' value={ssn} onEnter={handleSubmit} onChange={setSsn} className={hasError ? 'input-error' : ''}>
                            {hasError && <InputNote className="text-danger">Please enter your Social Security number.</InputNote>}
                        </SocialSecurityNumberInput>
                    </InputLabel>
                </div>
            </>
        )
    }

    const renderButtons = () => {
        return (
            <>
                <Button variant="light" onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" onClick={handleSubmit}>Confirm</Button>
            </>
        );
    }

    return (
        <GenericModal {...props} disableBackdropClick={true} renderContent={renderBody} renderButtons={renderButtons} onHide={handleCancel} />
    );
}

export default SocialSecurityNumberModal;
